.Typist {
  display: inline;
}

li.active > a {
  color: #2f55d4 !important;
}

.pagination .active a {
  color: #ffffff !important;
}

a {
  &:hover {
    cursor: pointer;
  }
}

.accordian-custom {
  border: none;
}

#mt-1 {
  margin-top: 5px !important;
}

#mb-1 {
  margin-top: 5px !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.masonry-container {
  width: 100%;
  margin: auto;
}
.masonry {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  width: 100%;
  margin: auto;
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: stretch;
  flex-grow: 1;
}
.tile {
  img {
    width: 100%;
  }
}

.indicator-inactive,
.indicator-active {
  height: 12px !important;
  width: 6px !important;
  border-radius: 4px;
  border: none;
  margin-left: 7px;
  margin-right: 7px;
}

.indicator-inactive {
  background-color: #2f55d4 !important;
  opacity: 0.6;
}
.indicator-active {
  background-color: #2f55d4 !important;
  transform: rotate(45deg);
}

.custom-owl-dots {
  margin-top: 10px;
  text-align: center;
}

.classForContainer {
  position: fixed;
  right: -100px;
  bottom: 30px;
  transition: right 0.5s;
  cursor: pointer;
  background-color: rgba(47, 212, 69, 0.2);
  padding: 5px;
  align-items: center;
  border-radius: 5px !important;
  outline: none;
  transition: 0.15s ease-in;

  &:hover {
    transition: 0.15s ease-in;
    background-color: #1ac45e !important;
    svg {
      transition: 0.15s ease-in;
      stroke: $white !important;
    }
  }

  svg {
    height: 16px;
    width: 16px;
    vertical-align: middle;
    overflow: hidden;
    margin-top: 2px;
    margin-left: 5px;
    fill: none;
    stroke: #1ac45e !important;
    transition: 0.15s ease-in;
  }
}

.classForTransition {
  right: 30px;
}
//masonry

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.accordion {
  .card {
    .faq {
      > .active-link {
        // color: $primary !important;
      }
    }
  }
}

.hover-border {
  .nav-link {
    &:focus,
    &.active,
    &:active {
      outline: none !important;
      border: transparent !important;
    }
    &:hover {
      border-color: transparent !important;
    }
  }
}
.custom-dots {
  .indicator-active {
    background-color: #2f55d4 !important;
  }
}
.custom-dots {
  .indicator-inactive {
    background-color: #6486e4 !important;
  }
}

.close {
  background: transparent;
  border: 0;
  font-size: 24px;
  padding: 1.35rem 1.25rem;
  color: #000 !important;
  background: transparent
    escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
    )
    center / 1em auto no-repeat;
  position: absolute !important;
  top: -7px;
  right: 20px;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
}

[class^="uil-"],
[class*=" uil-"] {
  &:before {
    margin: 0;
  }
}
.footer-border-bottom {
  border-bottom: 1px solid #283353;
}

// ********************* Falcão mobile

.falcao-container {
  width: 100%;
  height: unset;
  background: transparent linear-gradient(180deg, #1ac45e 0%, #00682a 100%) 0% 0% no-repeat padding-box !important;
  border-radius: 15px !important;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;

  .falcao-texts {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    .falcao-badges {
      .badge {
        margin-right: 10px !important;
      }
    }

    .btn-outline-falcao {
      border: 1px solid #ffffff;
      color: white;
      width: 200px;

      &:hover {
        color: black;
        border: 1px solid white;
        background-color: white;
      }
    }
  }

  .falcao-image {
    img {
      display: flex;
      width: 100%;
      max-width: 450px;
      height: auto;
      margin: 0 auto !important;
    }
  }
}
//  ** Falcão Desktop

.header-btn {
  display: none;
}

.header-profile {
  display: none !important;
}

.header-profile-mobile {
  display: flex !important;

  .logout-btn {
    background-color: transparent !important;
    border: transparent !important;
    box-shadow: none !important;
    padding: 0px;

    &:hover {
      background-color: transparent !important;
    }
  }
  
}

.navigation-menu-mobile {
  max-height: unset !important;
  height: 80vh !important;
  flex-direction: column;
}

.detalhes-header{
  display: none !important;
}

.detalhes-mobile{
  display: flex !important;
}

.detalhes-invoice{
  display: none !important;
}

@media (min-width: 768px) {
  .detalhes-header{
    display: flex !important;
  }

  .detalhes-mobile{
    display: none !important;
  }

  
}

@media (min-width: 992px) {
  .detalhes-invoice {
    display: block !important;
    position: relative !important;
    
    &::after {
      content: "";
      height: 1px !important;
      position: absolute;
      width: 100%;
      background-color: #111111;
      top: 70px;
      left: 0;
    }
  }
  
  .falcao-container {
    width: 100%;
    height: 417px;
    background: transparent linear-gradient(180deg, #1ac45e 0%, #00682a 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 15px !important;
    display: flex;
    flex-direction: row;
    max-width: unset;

    .falcao-texts {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 3rem;
      .falcao-badges {
        .badge {
          margin-right: 10px !important;
        }
      }
    }

    .falcao-image {
      position: relative;

      img {
        position: absolute;
        bottom: 0;
        left: -50px;
        // height: 100%;
        max-height: 485px !important;
        width: auto;
        margin-left: unset;
      }
    }
  }

  .header-btn {
    display: inline;
  }

  .registro-header {
    display: none !important;
  }

  .header-profile {
    display: flex !important;
  }

  .header-profile-mobile {
    display: none !important;
  }
}

// ****************
.perguntas-frequentes {
  min-height: 218px;
  background-color: #1ac45e;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sobre-list {
  list-style: none;
  padding-left: 0;
}

.info-bar {
  padding-left: 15px;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    left: 0px;
    height: 30px !important;
    width: 5px !important;
    background-color: #1ac45e;
    margin: 0 auto;
  }
}

#home {
  width: 100%;
  height: 100%;
  background-color: black;
}

.footerzinho {
  position: absolute;
  bottom: 0;
  margin: 0 auto;
}

.pricing-selector {
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    max-width: 450px;
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
    border-radius: 22px;
    height: 45px;
    padding: 0px;

    li {
      min-width: 150px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;

      p {
        margin: 0;
      }
    }

    .price-selected {
      background-color: #1ac45e !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 22px;
    }
  }

  .price-discount {
    font-size: 12px;
    background-color: #2f55d4;
    border-radius: 22px;
    padding: 0px 5px;
  }
}

.pricing-rates {
  background: transparent linear-gradient(180deg, #101010 0%, #080808 100%) 0% 0% no-repeat padding-box !important;
}

.tab-pane {
  width: 100% !important;
  max-width: 733px;
}

.social-icon {
  li {
    .social-media-icons {
      border: none !important;
      &:hover {
        background-color: unset !important;

        .fea {
          fill: #1ac45e !important;
        }
      }
    }
  }
}

.classic-saas-image {
  z-index: 1 !important;
}

.bg-app-shape {
  &::after {
    transform: rotate(40deg) !important;
    left: -42rem !important;
    background: transparent linear-gradient(90deg, #000000 0%, #052311 100%);
    box-shadow: none !important;
    border: none;
  }
}
.bg-saas-shape {
  &::after {
    background: transparent linear-gradient(90deg, #000000 0%, #052311 100%);
    box-shadow: none !important;
    border: none;
  }
}

.landing-title {
  font-size: 49px !important;
}

.section-img {
  display: flex;
  width: 100%;
}

@media (min-width: 576px) {
  .bg-app-shape {
    &::after {
      left: -25rem !important;
    }
  }
}

.showcase {
  z-index: 0 !important;
}

.logo-dark-mode {
  min-height: 50px !important;
}

.validator-msg {
  font-size: 14px;
}

.etapas-registro {
  .etapa {
    font-size: 14px;
    color: #94979a;
    margin-bottom: 0px !important;
  }

  .etapa-title {
    font-size: 20px;
    font-weight: bold;
  }
}

.etapa-plano-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #101010;
  // background: rgb(16,16,16);
  // background: linear-gradient(0deg, rgba(16,16,16,1) 0%, rgba(16,16,16,1) 100%);  
  border: none;
  border-radius: 9px;
  min-height: 85px;
  color: #1ac45e;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  .plano-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 15px;

    .plano-radio-button {
      height: 19px !important;
      width: 19px !important;
      border: 1px solid #1ac45e;
      border-radius: 50%;
    }

    .plano-button-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 15px;

      .plano-title {
        font-size: 14px;
        color: white;
      }

      .plano-description {
        font-size: 12px;
      }

      p {
        margin: 0;
      }
    }
  }

  .plano-button-price {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    margin-right: 15px;

    p {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      color: white;
    }
  }

  &:hover,
  &:focus {
    border: 1px solid #1ac45e;
    color: #1ac45e !important;

    .plano-button-container {
      .plano-radio-button {
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: none;
          content: " ";
          background-color: #1ac45e;
        }
      }
    }
  }

  &.active {
    border: 1px solid #1ac45e;
    color: #1ac45e !important;

    .plano-button-container {
      .plano-radio-button {
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: none;
          content: " ";
          background-color: #1ac45e;
        }
      }
    }
  }
}

.selected-plano {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #101010;
  min-height: 85px;
  border-radius: 9px;

  .selected-plano-container {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    .selected-plano-title {
      font-size: 14px;
      margin: 0;
    }

    .selected-plano-price {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
    }
  }
  .selected-plano-price {
    margin-right: 15px;
    a {
      p {
        margin: 0;
      }
    }
  }
}

.plano-privacy {
  p {
    font-size: 12px;
    color: #94979a;
    font: normal normal 600 14px/19px;
  }
}

.overflow-teste {
  max-height: 750px;
  overflow-x: hidden;
  overflow-y: auto;
}

.etapa-1-row {
  width: 100%;
}

.register-etapas {
  background-color: black !important;
  z-index: 1;
  padding: 15px 0;
}

.header-profile {
  .logout-btn {
    background-color: transparent !important;
    border: transparent !important;
    box-shadow: none !important;

    &:hover {
      background-color: transparent !important;
    }
  }
}

.passo-5 {
  overflow-y: scroll;
  max-height: 600px;
  padding: 2px;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1ac45e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #059740;
}

.detalhes-line{
  height: 1px !important;
    width: 100%;
    background-color: white;
}

.card-container {
  background-color: #3c4858 !important;

  p {
    text-decoration: none;
    color: white;
  }
}

.delete-card {
  position: absolute;
  right: 15px;
  top: 15px;
  color: white;
  background-color: transparent;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;

  &:hover {
    background-color: #2a333e;
    color: #e91e35;
  }
}

.selected-default-icon {
  position: absolute;
  right: 55px;
  top: 15px;
  color: white;
  background-color: transparent;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;

  &:hover {
    background-color: #2a333e;
    color: #1ac45e;
  }
}

.selected-default-card {
  color: #1ac45e !important;
  background-color: #2a333e;
}

.default-card {
  border: 2px solid #1ac45e !important;
}

.modal-fatura {
  background-color: transparent !important;

  .modal-content {
    background: transparent !important;
    border: none !important;
  }

  .modal-icon {
    float: right;
    height: 25px !important;
    width: 25px !important;
    color: white !important;
    transition: 0.2s ease-in;

    &:hover {
      color: #1ac45e !important;
      transition: 0.2s ease-in;
    }
  }
}

.profile-link {
  color: white;
  transition: unset !important;
  background: unset !important;
  background-color: unset !important;
  transition: color 0.2s ease-in !important;

  &:hover {
    transition: color 0.2s ease-in !important;
    color: #1ac45e !important;
  }
}

.tooltip{

  .tooltip-inner{
    background-color: #272727 ;
  }

  .arrow{
    background-color: yellow;
    left: 0 !important;
  }
}

.underline{
  text-decoration: underline !important;
}

.modal-body.modal-download{
  padding: 0px !important;
}
